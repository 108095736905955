









































































































import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import api from "@/api";

@Component({
  name: "adjustLogList",
  components: {
    PagedTableView,
  },
})
export default class adjustLogList extends Vue {
  queryForm = {
    number: undefined,
    userName: undefined,
    customerCode: undefined,
  };
  productSkuId = 0;

  // 获取表数据
  created() {
    this.productSkuId = Number(this.$route.params.id);
    this.fetchData(this.queryForm);
  }

  fetchData(params: any) {
    params.productSkuId = this.productSkuId;
    return api.productRevisePriceRecord.getAll(params);
  }
}
